const settings =
{
    layout :
    {
        style  : 'default',
        config :
        {
            scroll : 'content',
            navbar :
            {
                display  : true,
                folded   : false,
                position : 'left'
            },
            toolbar:
            {
                display  : true,
                style    : 'fixed',
                position : 'below'
            },
            footer :
            {
                display  : false,
                style    : 'fixed',
                position : 'below'
            },
            mode : 'fullwidth'
        }
    },
    theme :
    {
        footer : 'light',
        main   : 'default',
        navbar : 'light' ,
        toolbar: 'light'
    }
};

export default settings;
