import run from 'xyz/src/app/run'

import assets   from './app/assets'
import locale   from './app/locale'
import routes   from './app/routes'
import settings from './app/settings'
import style    from './app/styles/index.css'
import themes   from './app/themes'
import version  from './app/version'

run({
    assets,
    locale,
    routes,
    settings,
    style,
    themes,
    version
}) ;

