import dark from 'xyz/src/app/styles/colors/dark'

export const defaults =
{
    default :
    {
        palette:
        {
            type    : 'light',
            primary :
            {
                light        : dark[400],
                main         : dark[600],
                dark         : dark[700],
                contrastText : '#fff'
            },
            secondary :
            {
                light        : '#F95D5D',
                main         : '#e53e3f',
                dark         : '#E81C1C',
                contrastText : '#fff'
            }
        },
        status : { danger : 'orange' }
    },
    defaultDark:
    {
        palette:
        {
            type       : 'dark',
            background :
            {
                paper   : '#fff',
                default : '#fafafa'
            },
            primary :
            {
                light        : dark[400],
                main         : dark[600],
                dark         : dark[700],
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#F95D5D',
                main         : '#e53e3f',
                dark         : '#E81C1C',
                contrastText : '#fff'
            },
            text :
            {
                primary   : "rgba(255, 255, 255, 0.87)",
                secondary : "rgba(255, 255, 255, 0.54)",
                disabled  : "rgba(255, 255, 255, 0.38)",
                hint      : "rgba(255, 255, 255, 0.38)"
            }
        },
        status : { danger : 'orange' }
    }
};

const themes =
{
    ...defaults,

    sunset :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light : '#c76a1d',
                main  : '#ff994c',
                dark  : '#ffca7b',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#d0605e',
                main         : '#d0605e',
                dark         : '#9b3134',
                contrastText : '#fff'
            },
        },
        status :
        {
            danger: 'orange'
        }
    },
    greeny :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light: '#6cabd4',
                main : '#387ca3',
                dark : '#005074'
            },
            secondary:
            {
                light       : '#ABC13D',
                main        : '#819b24',
                dark        : '#617B3F',
                contrastText: '#fff'
            },
        },
        status :
        {
            danger : 'orange'
        }
    },
    beach :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light        : '#c4d8dd',
                main         : '#93a7ab',
                dark         : '#65787c',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#ffb281',
                main         : '#f18153',
                dark         : '#ba5228',
                contrastText : '#fff'
            }
        }
    },
    tech :
    {
        palette:
        {
            type    : 'light',
            primary :
            {
                light        : '#29b6f6',
                main         : '#039be5',
                dark         : '#0288d1',
                contrastText: '#fff'
            },
            secondary:
            {
                light: '#aed581',
                main : '#8bc34a',
                dark : '#689f38',
                contrastText : '#fff'
            }
        }
    },
    sweetHues  :
    {
        palette:
        {
            type     : 'light',
            primary  :
            {
                light        : '#d5c1eb',
                main         : '#a391b9',
                dark         : '#746389',
                contrastText : '#fff'
            },
            secondary:
            {
                light        : '#90afd4',
                main         : '#6080a3',
                dark         : '#325474',
                contrastText : '#fff'
            }
        }
    },
    deepOcean  :
    {
        palette :
        {
            type     : 'dark',
            primary  :
            {
                light : '#8f53e7',
                main  : '#5a24b4',
                dark  : '#1e0083',
                contrastText : '#fff'
            },
            secondary:
            {
                light       : '#ff61ff',
                main        : '#fe00e9',
                dark        : '#c600b6',
                contrastText: '#fff'
            }
        }
    },
    slate :
    {
        palette:
        {
            type     : 'dark',
            primary  :
            {
                light: '#86fff7',
                main : '#4ecdc4',
                dark : '#009b94',
                contrastText : '#fff'
            },
            secondary:
            {
                light       : '#ff9d99',
                main        : '#ff6b6b',
                dark        : '#c73840',
                contrastText: '#fff'
            }
        }
    }
};

export default themes ;
