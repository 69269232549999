import { getSettings } from 'xyz/src/app/routes/getSettings'

export const defaultTheme =
{
    main   : 'default',
    navbar : 'light',
    toolbar: 'light',
    footer : 'light'
};

const init = getSettings({ defaultTheme }) ;

// default
const settings = init()  ;

// welcome, register, token
const simple = init(
{
    footer  : { display : false } ,
    navbar  : { display : false } ,
    toolbar : { display : false }
});

export const defaultSettings =
{
    // ------ account

    '/me' : settings ,

    // ------- applications

    '/applications/'    : settings ,
    '/applications/:id' : settings ,

    // ------- articles

    '/articles/' : settings ,

    // ------- brands

    '/brands/' : settings ,

    // ------- conceptualObjects

    '/conceptualObjects/' : settings ,

    // ------- courses

    '/courses/' : settings  ,

    // ------- diseases

    '/diseases/'    : settings ,
    '/diseases/:id' : settings ,

    // ------- events

    '/events/' : settings ,

    // ------- games

    '/games/'              : settings ,
    '/games/courses/:id'   : settings ,
    '/games/questions/:id' : settings ,
    '/games/:id'           : settings ,

    // ------- home

    '/home' : settings ,

    // ------- livestocks

    '/livestocks/'                 : settings ,
    '/livestocks/observations/:id' : settings ,
    '/livestocks/workshops/:id'    : settings ,
    '/livestocks/:id'              : settings ,

    // ------- laboratories

    '/medicalLaboratories'     : settings ,
    '/medicalLaboratories/:id' : settings ,

    // ------- mediaObjects

    '/mediaObjects/' : settings ,

    // ------- organizations

    '/organizations/' : settings ,

    // ------- people

    '/people/' : settings ,

    // ------- places

    '/places/' : settings ,

    // ------- register

    '/register' : simple ,

    // ------- settings

    '/settings/'    : settings ,
    '/settings/:id' : settings ,

    // ------- stages

    '/stages/' : settings ,

    // ------- teams

    '/teams/'    : settings ,
    '/teams/:id' : settings ,

    // ------- technicians

    '/technicians'     : settings ,
    '/technicians/:id' : settings ,

    // ------- thesaurus

    '/thesaurus/'    : settings ,
    '/thesaurus/:id' : settings ,

    // ------- token

    '/redirect' : simple ,

    // ------- users

    '/users/'    : settings ,
    '/users/:id' : settings ,

    // ------- veterinarians

    '/veterinarians/'    : settings ,
    '/veterinarians/:id' : settings ,

    // ------- welcome

    '/welcome' : simple
};

export default defaultSettings ;
